import React, { useCallback } from 'react';
import './navbar.scss';
import GFLogo from '../../../../assets/images/logos/gigafact_logo_full.svg';
import { Badge, Button, Menu, Dropdown, Tooltip } from 'antd';
import { UserOutlined, BellOutlined } from '@ant-design/icons';
import { CognitoLogout } from '../../authentication/authentication';
import Utils from '../../../../utils/utils';

const Navbar = ({ isLogin, setopenNotificaiton, notifications }) => {
  const userMenuClick = useCallback(({ key }) => {
    if (key === "Logout") {
      CognitoLogout().then(() => {
        window.location.href = "/partnerconsole/login";
        localStorage.clear();
      });
    }
  }, []);

  const UserMenu = (
    <Menu onClick={userMenuClick} className="user-menu">
      <Menu.Item key="Logout" className='user-menu-items'>
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const handleRedirect = useCallback(async (e) => {
    e.preventDefault();
    // const redirectPath = await Utils.getRedirectPath();
    // window.location.href = 'https://gigafact.org';
    window.open('https://gigafact.org', "_blank");
  }, []);

  return (
    <div className="navbar-container">
      <div className="logo-block">
        <a href="/" onClick={handleRedirect}>
          <img className="fs-logo" src={GFLogo} alt="GigaFact Logo" />
        </a>
      </div>
      {isLogin && (
        <div className="right-panel">
          <div className="notification-block">
            <div className='notification-btn' onClick={() => setopenNotificaiton(true)}>
              <Badge count={notifications?.unread_count || null}>
                <BellOutlined className='notification-icon' />
              </Badge>
            </div>
          </div>
          <div className="profile-block">
            <Tooltip placement="leftTop" title={`${localStorage.getItem('firstname')} ${localStorage.getItem('lastname')}`}>
              <Dropdown overlay={UserMenu} trigger={["click"]} placement="bottomRight">
                <Button shape="circle" icon={<UserOutlined />} />
              </Dropdown>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;